.expense-permissions {
  width: 504px;
  padding: 80px 0;
  margin: auto;
}

.tooltip {
  width: 100%;
}

.hint {
  display: flex;
}

.hint-icon {
  flex-shrink: 0;
  margin-top: 5px;
  margin-right: 12px;
  color: var(--content-accent);
}

.spend-limits {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
}
