.section + .section {
  margin-top: 16px;
}

.section:last-of-type:not(:first-of-type) {
  border-bottom: 1px solid var(--gray300);
}

.title {
  margin-top: 0;
  white-space: pre-wrap;
}

.description {
  margin-top: 0;
  line-height: 16px;
  color: var(--invoice-color-text-66);
  white-space: pre-wrap;
}

.totals {
  width: 296px;
  margin-right: 0;
  margin-left: auto;
  border-collapse: collapse;
}

.cell {
  padding: 8px;
  vertical-align: baseline;
}

.amount {
  text-align: right;
  white-space: nowrap;
}
