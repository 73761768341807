.loading {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.skeleton {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.lines {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
