.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: hidden;
}

.wrapper table td,
.wrapper table th {
  position: relative;
}

.wrapper table td:hover::after {
  position: absolute;
  top: -5000px;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 10000px;
  pointer-events: none;
  content: '';
  background-color: var(--background-secondary);
  opacity: 0.3;
}

.chartOverlay {
  position: absolute;
  top: 0;
  z-index: var(--z-index-high);
  width: 100%;
}
