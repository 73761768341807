.wrapper {
  color: var(--content-primary-a);
  border: none;
  padding: 0;
  background-color: var(--elevation-default);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    width: max-content;
    margin-top: 8px;
  }
}

@media only screen and (width >= 1369px) {
  .wrapper {
    padding: 48px 48px 52px 48px;
    border: 1px solid var(--border-tertiary);
    border-radius: 8px;
  }
}
