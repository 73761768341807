.payment-method-icons-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.payment-method-icon {
  rect {
    width: 38px;
    height: 20px;
    fill: var(--background-tertiary);
    x: 2;
    y: 10;
  }
}

.paid-payment-method-icon {
  position: relative;
  top: -8px;
}

.details-list {
  dd {
    align-content: center;
  }
}

.paid-details-list {
  dd {
    height: 24px;
  }
}
