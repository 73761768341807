@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**
 * This case is needed in case of unexpected number of colunms
 * so the chartoverlay fallbacks to always be visible.
 */
.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.fadein {
  composes: invisible;
  animation: fadeIn calc(var(--total-delay, 0s)) ease-in forwards;
}
