.cell {
  padding-right: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid var(--border-tertiary);

  @media only screen and (width < 1368px) {
    border-bottom: none;
  }
}

.supplier-container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-label-container {
  display: flex;
}

.cell-label {
  position: relative;
  display: block;

  :global(.tooltip-target) {
    margin-left: -4px;
  }
}

.missing {
  color: var(--content-tertiary);
}

.flex-label {
  display: flex;
  justify-content: flex-start;
  min-width: 0;
}

.sub-label {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.with-separator {
  display: flex;
  align-items: center;

  &::before {
    display: inline-block;
    width: 2px;
    height: 2px;
    margin: 0 8px;
    content: '';
    background-color: var(--content-secondary);
    border-radius: 2px;
  }
}

[data-tooltip-wrapper='warning'] .file-name-warning {
  width: 12px;
  height: 12px;
  margin-bottom: -1px;
  color: var(--content-warning);
}

[data-tooltip-wrapper='self-invoice'] .self-invoice-icon {
  display: flex;
  width: 12px;
  height: 12px;
}

[data-tooltip-wrapper='linked-invoices'].linked-invoices-icon {
  position: relative;
  top: 2px;
  display: flex;
  width: 14px;
  height: 14px;
  margin-left: 2px;
}
