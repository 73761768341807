.addresses {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  gap: 24px;

  &.DE {
    flex-direction: column;
    justify-content: unset;
    gap: 40px;
  }
}
