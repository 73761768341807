.wrapper {
  display: grid;
  flex-direction: column;
  width: 100%;
}

.title {
  color: var(--content-secondary);
}

.payments {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.error {
  width: 100%;
}

.load {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}

.more {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 14px;
}

.chevron {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
