.wrapper {
  position: relative;
  display: grid;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  overflow: hidden;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.wrapper--variant-low {
  background-color: var(--elevation-low);
  border: none;
}

.wrapper--with-animation {
  --translate-initial-offset: -20px;

  overflow: hidden;
  opacity: 0;
  transform: translateY(var(--translate-initial-offset));
  animation: fade-in 0.25s ease-out 0.25s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(var(--translate-initial-offset));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.wrapper:has(> .image) {
  grid-template-columns: auto 90px;
  padding: 24px 0 24px 24px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content > * {
  width: fit-content;
}

.image {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.image img {
  width: 90px;
}

.close {
  position: absolute;
  top: 4px;
  right: 4px;
}
