@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes translateX {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.sidebar {
  --sidebar-width: 400px;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: calc(var(--z-index-above) + 2);
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  height: 100%;
  overflow-y: auto;
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);
  color: var(--content-primary-a);

  @media (prefers-reduced-motion) {
    --overlay-sidebar-animation-duration: 0ms;
  }

  &[data-entering] {
    animation: translateX var(--overlay-sidebar-animation-duration);
  }

  &[data-exiting] {
    animation: translateX var(--overlay-sidebar-animation-duration) reverse ease-in-out;
  }
}

.dialog {
  height: 100%;

  &:has(.footer) {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh + 112px);
  }

  &:focus-visible {
    box-shadow: none;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: calc(var(--z-index-above) + 1);
  min-width: 100vw;
  height: 100%;
  background-color: var(--background-panel-overlay);

  &[data-entering] {
    animation: fadeIn var(--overlay-sidebar-animation-duration);
  }

  &[data-exiting] {
    animation: fadeIn var(--overlay-sidebar-animation-duration) reverse ease-in;
  }
}

.header {
  display: flex;
  flex-direction: column;
}

.top-header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-above);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--elevation-high);
}

.separator {
  height: 1px;
  margin-top: unset;
  background-color: var(--border-tertiary);
  border: 0;
}

.container {
  padding: 0 24px 16px;
}

.footer {
  width: 100%;
  margin-top: auto;
}

.safe {
  height: 112px;
}
