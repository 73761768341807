.table-cell {
  border-bottom: 1px solid var(--border-tertiary);
}

.table-cell:has([data-testid='transaction-cell']) {
  border-bottom: none;
}

.table-cell:focus-visible {
  box-shadow: 0 0 0 4px inset var(--border-accent-low);
}

.table-cell:hover {
  border-bottom: 1px solid var(--border-secondary);
}

.table-cell:hover:has([data-testid='transaction-cell']) {
  border-bottom: none;
}

.table-cell:has([data-testid='transaction-cell'])::after {
  position: absolute;
  bottom: 0;
  left: 48px;
  width: calc(100% - 48px);
  content: '';
  border-bottom: 1px solid var(--border-tertiary);
}

.table-cell:has([data-testid='transaction-cell']):hover::after {
  border-bottom: 1px solid var(--border-secondary);
}

.table-cell:has([data-is-dragging]) {
  position: unset !important;
}

.table-cell-content {
  display: flex;
  align-items: center;
}

.fixed-column {
  display: flex;
  gap: 16px;
}
