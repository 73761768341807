.animation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.lottie {
  width: 68px;
}
