.label {
  margin-bottom: 12px;
  color: var(--content-secondary);
  white-space: nowrap;
}

.value {
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 0;
  overflow: hidden;

  &:not(:has(.disabled)) {
    &:hover {
      background-color: var(--background-tertiary);
      border-radius: 4px;
    }
  }
}

.link {
  overflow: hidden;
  color: var(--content-primary);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.disabled {
    color: var(--content-tertiary);
    text-decoration: line-through;
    cursor: default;
  }
}

.copy-btn {
  display: flex;
  align-items: center;
  padding-right: 0;
  cursor: copy;
}
