.actions {
  display: flex;
  padding: 12px 16px;
  border-top: 1px solid var(--border-tertiary);
}

.actions-background {
  background-color: var(--elevation-default);
}

.actions-btn {
  flex: 1 1 100%;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}
