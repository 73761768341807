.payment {
  height: 48px;
  width: 100%;
  display: grid;
  grid-template-columns: 48px 1fr 32px;
  gap: 16px;
  align-items: center;
  position: relative;
}

.method {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.method svg {
  width: 48px;
  height: 48px;
}

.info {
  overflow: hidden;
}

.from {
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
}

.date {
  color: var(--content-secondary);
}

.trigger {
  position: relative;
  z-index: var(--z-index-default);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: var(--background-tertiary);
  }

  &[data-pressed] {
    background-color: var(--background-secondary);
  }
}

.popover {
  width: 206px;
  padding: 8px 0;
  overflow: auto;
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);
  border-radius: 4px;
}

.list-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  box-sizing: border-box;
  overflow: hidden;
  color: var(--content-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 12px;
  font-size: 14px;
  width: 100%;
}
